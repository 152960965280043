import styled, { css } from 'styled-components';

import { ScrollTriggerStyledProps } from './types';
import Typography from '../../styles/Typography';
import { transition } from '../../../common/mixins';
import Button from '../Button';

export const ScrollLabel = styled(Typography)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.neutral10};
`;

export const ScrollBtn = styled(Button)<ScrollTriggerStyledProps>`
  position: relative;
  padding-left: 5rem;

  &::after,
  &::before {
    ${transition()}
    position: absolute;
    content: '';
    top: calc(50% - 1px);
    height: 0.125rem;
  }

  &::before {
    width: 4.5rem;
    left: 0;
    background-color: ${({ theme }) => theme.colors.additional.accentGray2};
  }

  &::after {
    width: 2rem;
    left: 2.5rem;
    background-color: ${({ theme }) => theme.colors.neutral.neutral10};
  }

  &:hover::after {
    width: 4.5rem;
    left: 0;
  }

  ${({ isDark }) =>
    isDark &&
    css`
      ${ScrollLabel} {
        color: ${({ theme }) => theme.colors.additional.dark};
      }
      &::before {
        background-color: ${({ theme }) => theme.colors.secondary.hover};
      }
      &::after {
        background-color: ${({ theme }) => theme.colors.additional.accentGray2};
      }
    `}
`;
