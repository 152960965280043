import styled, { css } from 'styled-components';
import { transition } from '../../../common/mixins';
import { CarouselPaginationDotsStyledProps } from './types';

export const CarouselPagination = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  height: 0.5rem;
  width: 0.5rem;
  opacity: 0;
  padding: 0;
`;

export const CarouselPaginationDots = styled.ul<CarouselPaginationDotsStyledProps>`
  width: 100%;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > li {
    position: relative;
    margin: 0 0.375rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.additional.accentGray2};
  }

  & > li.slick-active {
    width: 0.75rem;
    height: 0.75rem;
    background-color: ${({ theme }) => theme.colors.secondary.hover};

    ${CarouselPagination} {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  & > li.slick-active ~ li {
    background-color: ${({ theme }) => theme.colors.additional.accentGray1};
  }

  ${({ hasLightBg, theme }) =>
    hasLightBg &&
    css`
      &.slick-dots > li,
      &.slick-dots > li.slick-active ~ li {
        background-color: ${theme.colors.additional.accentGray1};
      }

      &.slick-dots > li.slick-active {
        background-color: ${theme.colors.additional.accentGray2};
      }
    `}
`;

export const slickArrowBase = css`
  ${transition('color')}
  display: flex;
  font-size: 2rem;
  padding: 0;
  width: 2rem;
  height: 2rem;
  border: none;
  outline: none;
  border-radius: 50%;
  background: none;
  color: ${({ theme }) => theme.colors.secondary.hover};

  &.slick-disabled {
    cursor: default;
    color: ${({ theme }) => theme.colors.additional.accentGray1};
  }
`;

export const CarouselNextArrow = styled.button`
  ${slickArrowBase}
`;

export const CarouselPrevArrow = styled.button`
  ${slickArrowBase}
  transform: rotate(180deg);
`;
