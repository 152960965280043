import styled from 'styled-components';

import media from '../../../common/MediaQueries';
import Typography from '../../styles/Typography';

export const Heading = styled.div``;

export const HeadingSubtitle = styled(Typography)`
  text-transform: uppercase;
  margin-bottom: 1rem;

  @media ${media.maxPhone} {
    ${({ theme }) => theme.textS};
  }
`;

export const HeadingTitle = styled(Typography)`
  ${({ theme }) => theme.headingL};

  @media ${media.tablet} {
    ${({ theme }) => theme.headingXL};
  }
`;

export const HeadingHead = styled(Typography)``;

export const HeadingContent = styled.div`
  margin-top: 2rem;

  line-height: 1.5;
`;
