import React from 'react';

import { LinkMode } from '../Link/enums';
import * as Styled from './styles';
import { ScrollTriggerProps } from './types';

const ScrollTrigger: React.FC<ScrollTriggerProps> = ({ label, onClick, isDark }) => (
  <Styled.ScrollBtn {...{ onClick, isDark }} mode={LinkMode.HasPlainLook}>
    <Styled.ScrollLabel variant="textM">{label}</Styled.ScrollLabel>
  </Styled.ScrollBtn>
);

export default ScrollTrigger;
