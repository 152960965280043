import React from 'react';

import RichText from '../RichText';
import * as Styled from './styles';
import { HeadingProps } from './types';

const Heading: React.FC<HeadingProps> = ({
  customTitleTag,
  title,
  subtitle,
  content,
  contentRichText,
  hasDarkBackground,
  hasLargeTitle,
}) => (
  <Styled.Heading>
    {subtitle && <Styled.HeadingSubtitle variant="textM">{subtitle}</Styled.HeadingSubtitle>}
    {hasLargeTitle ? (
      <Styled.HeadingHead variant="headingXXXL">{title}</Styled.HeadingHead>
    ) : (
      <Styled.HeadingTitle as={customTitleTag}>
        {title}
      </Styled.HeadingTitle>
    )}
    {(content || contentRichText) && (
      <Styled.HeadingContent>
        {content && content}
        {contentRichText && <RichText data={contentRichText} {...{ hasDarkBackground }} />}
      </Styled.HeadingContent>
    )}
  </Styled.Heading>
);

export default Heading;
